<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-row class="filterSection">
                <b-col md="8">
                    <b-row>
                        <b-col md="4" v-if="userType=='admin' || userType=='clinic'">
                            <base-input :label="$t('availability.doctor_list')">
                                <el-select v-model="filter.doctor" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                                    <el-option v-for="(item, index) in doctors" :key="index" :label="item.title+item.name+' - '+item.email+' - '+item.phone" :value="item.uuid"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="6">
                            <v-date-picker v-model="range" mode="dates" :locale="userLang" :masks="masks" is-range @input="setDates">
                                <template v-slot="{ inputValue, inputEvents, isDragging }">
                                    <b-row>
                                        <b-col md="6">
                                            <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                            <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                        </b-col>
                                        <b-col md="6">
                                            <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                            <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                        </b-col>
                                    </b-row>
                                </template>
                            </v-date-picker>
                        </b-col>
                        <b-col md="2">
                            <base-input :label="$t('availability.type')">
                                <el-select v-model="filter.type" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                                    <el-option :label="$t('availability.online')" value="online"></el-option>
                                    <el-option :label="$t('availability.normal')" value="normal"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="4" class="text-right pt-4">
                    <base-button type="success" @click="openModal('create')" class="my--5" size="sm">
                        <i class="fas fa-sync-alt"></i> {{$t('availability.create_new')}}
                    </base-button>
                    <base-button type="warning" @click="openModal('update')" class="my--5" size="sm">
                        <i class="fas fa-edit"></i> {{$t('availability.update')}}
                    </base-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body v-if="availability" class="p-4">
            <h3>{{filter.type=='normal'?$t('availability.normal'):$t('availability.online')}}</h3>
            <div class="table-responsive availabilityTable">
                <template v-if="availability.length>0">
                    <table class="table table-striped table-bordered" v-loading="tableLoading">
                        <tbody>
                            <tr v-for="(item, index) in availability" :key="item.data">
                                <td>
                                    {{item.data, 'DD.MM.YYYY ddd' | getIntDate}}
                                    <span class="badge badge-primary dblock">{{item.list[0].min}} Dk</span>
                                </td>
                                <td v-for="litem in availability[index].list" :key="item.data+'-'+litem.time" :class="'text-center table-'+(!litem.status || (today>litem.date)||(litem.date==today && nowTime>=litem.time)?'danger':(litem.available?'success':'warning'))">
                                    <span class="dblock"><b>{{litem.time | getIntTime}}</b></span>
                                    <span v-if="litem.status">{{litem.price}} {{litem.currency | getCurrency}}</span>
                                    <small v-else>{{$t('availability.close_date')}}</small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else>
                    <b-alert show variant="info">
                        {{$t('general.empty_table')}}
                    </b-alert>
                </template>
            </div>
        </b-card>

        <modal :show.sync="modal.status" modal-classes="modal-secondary" :size="modal.size" :showClose="false" :backdropClose="false" :hideFooter="true">
            <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.id=='create' ? $t('availability.create_new') : $t('availability.update')}}</h6>
                <hr>
                <template v-if="modal.id=='create'">
                    <b-form role="form" @submit.prevent="handleSubmit(onSubmitCreate)" v-loading="modal.loading">
                        <div class="form-group">
                            <b-row>
                                <b-col md="8" class="mt-2">
                                    <base-input type="text" name="price" :nameAs="$t('general.price')" :label="$t('general.price')+'*'" :placeholder="$t('general.price')" v-model="modal.create.price" :rules="{min_value:1, required: true, regex: /^\d*\.?\d*$/}">
                                    </base-input>
                                </b-col>
                                <b-col md="4" class="mt-2">
                                    <base-input :label="$t('general.currency')">
                                        <el-select v-model="modal.create.currency">
                                            <el-option v-for="(item, index) in currency" :key="index" :label="item" :value="index"></el-option>
                                        </el-select>
                                    </base-input>
                                </b-col>
                            </b-row>
                            <v-date-picker v-model="modal.create.range" :locale="userLang" mode="dates" :masks="masks" is-range @input="setModalDates" :min-date="new Date()">
                                <template v-slot="{ inputValue, inputEvents, isDragging }">
                                    <b-row>
                                        <b-col md="6">
                                            <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                            <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                        </b-col>
                                        <b-col md="6">
                                            <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                            <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                        </b-col>
                                    </b-row>
                                </template>
                            </v-date-picker>
                            <b-row>
                                <b-col md="6" class="mt-2">
                                    <label class="form-control-label">{{$t('availability.start_time')}}</label>
                                    <v-date-picker v-model="modal.create.rangeTime.start" is24hr mode="time" @input="setModalTime('create')" />
                                </b-col>
                                <b-col md="6" class="mt-2">
                                    <label class="form-control-label">{{$t('availability.end_time')}}</label>
                                    <v-date-picker v-model="modal.create.rangeTime.end" is24hr mode="time" @input="setModalTime('create')" />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="6" class="mt-2">
                                    <base-input :label="$t('availability.examination_period')">
                                        <el-select v-model="modal.create.min">
                                            <el-option v-for="item in 60" :key="item" :label="item+' '+$t('general.min')" :value="item"></el-option>
                                        </el-select>
                                    </base-input>
                                </b-col>
                                <b-col md="6" class="mt-2">
                                    <base-input :label="$t('availability.type')">
                                        <el-select v-model="modal.create.type" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                            <el-option :label="$t('availability.online')" value="online"></el-option>
                                            <el-option :label="$t('availability.normal')" value="normal"></el-option>
                                        </el-select>
                                    </base-input>
                                </b-col>
                            </b-row>
                        </div>

                        <b-alert show variant="warning" v-if="invalid">
                            <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                        </b-alert>
                        <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('general.create')}}</base-button>
                        <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                    </b-form>
                </template>
                <template v-if="modal.id=='update'">
                    <b-form role="form" @submit.prevent="handleSubmit(onSubmitUpdate)" v-loading="modal.loading" v-if="modal.update.dateAttr.min&&modal.update.dateAttr.max">
                        <div class="form-group">
                            <b-row>
                                <b-col md="7" class="text-center">
                                    <label>
                                        <small>
                                            <strong>{{$t('availability.select_process_date')}}</strong>
                                        </small>
                                    </label>
                                    <v-date-picker v-model="modal.range" mode="dates" :locale="userLang" :masks="masks" is-range @input="setModalRange" :min-date="new Date()">
                                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                                            <b-row>
                                                <b-col md="6">
                                                    <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                                    <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                                </b-col>
                                                <b-col md="6">
                                                    <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                                    <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                                </b-col>
                                            </b-row>
                                        </template>
                                    </v-date-picker>
                                    <div class="mt-4">
                                        <v-calendar :attributes="attributes" :locale="userLang" @dayclick="onDayClick" :min-date="new Date()" />
                                    </div>
                                </b-col>
                                <b-col md="5">
                                    <template v-if="modal.update.dateRange.length>0">
                                        <div class="text-center mt-2">
                                            <small>
                                                <strong>{{$t('availability.selected_day')}}</strong>
                                            </small>
                                        </div>
                                        <div class="dayList">
                                            <ul>
                                                <li v-for="(item, index) in modal.update.dateRange" :key="index">{{item, 'DD.MM.YYYY ddd' | getDate}}</li>
                                            </ul>
                                        </div>
                                    </template>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col md="12" class="mt--4 mb-3 text-center">
                                    <strong>{{$t('availability.avail_type')}} :</strong> {{$t('availability.'+filter.type)}}
                                </b-col>
                                <b-col md="4">
                                    <label class="form-control-label">{{$t('availability.status_update')}}</label>
                                    <b-form-checkbox v-model="modal.update.statusChange" class="switchCheck" name="check-button" switch>
                                        {{modal.update.statusChange ? $t('general.yes') : $t('general.no')}}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col md="4">
                                    <label class="form-control-label">{{$t('availability.price_update')}}</label>
                                    <b-form-checkbox v-model="modal.update.priceChange" class="switchCheck" name="check-button" switch>
                                        {{modal.update.priceChange ? $t('general.yes') : $t('general.no')}}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col md="4">
                                    <label class="form-control-label">{{$t('availability.time_range')}}</label>
                                    <b-form-checkbox v-model="modal.update.timeChange" class="switchCheck" name="check-button" switch>
                                        {{modal.update.timeChange ? $t('general.yes') : $t('general.no')}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row v-if="modal.update.statusChange">
                                <b-col md="12">
                                    <label class="form-control-label">{{$t('general.status')}}</label>
                                    <b-form-checkbox v-model="modal.update.status" class="switchCheck" name="check-button" switch>
                                        {{modal.update.status ? $t('general.active') : $t('general.passive')}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row v-if="modal.update.priceChange">
                                <b-col md="8" class="mt-2">
                                    <base-input type="text" name="price" :nameAs="$t('general.price')" :label="$t('general.price')+'*'" :placeholder="$t('general.price')" v-model="modal.update.price" :rules="{min_value:1, required: true, regex: /^\d*\.?\d*$/}">
                                    </base-input>
                                </b-col>
                                <b-col md="4" class="mt-2">
                                    <base-input :label="$t('general.currency')">
                                        <el-select v-model="modal.update.currency">
                                            <el-option v-for="(item, index) in currency" :key="index" :label="item" :value="index"></el-option>
                                        </el-select>
                                    </base-input>
                                </b-col>
                            </b-row>
                            <b-row v-if="modal.update.timeChange">
                                <b-col md="6" class="mt-2">
                                    <label class="form-control-label">{{$t('availability.start_time')}}</label>
                                    <v-date-picker v-model="modal.update.rangeTime.start" is24hr mode="time" @input="setModalTime('update')" />
                                </b-col>
                                <b-col md="6" class="mt-2">
                                    <label class="form-control-label">{{$t('availability.end_time')}}</label>
                                    <v-date-picker v-model="modal.update.rangeTime.end" is24hr mode="time" @input="setModalTime('update')" />
                                </b-col>
                            </b-row>
                        </div>
                        <b-alert show variant="warning" v-if="invalid || modal.update.dateRange.length===0">
                            <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                        </b-alert>
                        <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid || modal.update.dateRange.length===0">{{$t('general.update')}}</base-button>
                        <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                    </b-form>
                    <div v-else>
                        <b-alert show variant="info">
                            {{$t('warning.availability_update_info')}}
                        </b-alert>
                        <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                    </div>
                </template>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { DOCTOR_REQUEST, AVAILABILITY_REQUEST, AVAILABILITY_UPDATE, AVAILABILITY_ADD } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    data() {
        return {
            currency: { try: '₺', usd: '$', eur: '€', gbp: '£' },
            loading: false,
            tableLoading: false,
            masks: {
                input: 'DD.MM.YYYY'
            },
            modal: {
                loading: false,
                status: false,
                size: 'sm',
                id: '',
                create: {},
                update: {},
                range: {
                    start: '',
                    end: ''
                }
            },
            range: {
                start: moment()
                    .clone()
                    .startOf('month')
                    .toDate(),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .toDate()
            },
            filter: {
                type: 'online',
                doctor: '',
                start: moment()
                    .clone()
                    .startOf('month')
                    .format('YYYYMMDD'),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .format('YYYYMMDD')
            },
            today: Number(moment().format('YYYYMMDD'))
        };
    },
    computed: {
        ...mapState(['userLang']),
        ...mapGetters({ doctors: 'getDoctors', availability: 'getAvailabilities', userType: 'getUserType' }),
        attributes() {
            return self.modal.update.dateRange.map(date => ({
                highlight: true,
                dates: date
            }));
        },
        nowTime () {
            return Number(moment().format('HHmm'))
        }
    },
    methods: {
        setModalRange() {
            var dates = [];

            var currDate = moment(self.modal.range.start)
                .subtract(1, 'days')
                .startOf('day');
            var lastDate = moment(self.modal.range.end)
                .add(1, 'days')
                .startOf('day');

            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                dates.push(currDate.clone().toDate());
            }

            self.modal.update.dateRange = _.cloneDeep(dates);
            self.modal.update.dateRange = self.modal.update.dateRange.sort(function(a, b) {
                return new Date(a) - new Date(b);
            });
        },
        onDayClick(day) {
            if (!day.isDisabled) {
                const idx = self.modal.update.dateRange.findIndex(d => {
                    return moment(d).format('YYYYMMDD') === moment(day.date).format('YYYYMMDD');
                });
                if (idx >= 0) {
                    self.modal.update.dateRange.splice(idx, 1);
                } else {
                    self.modal.update.dateRange.push(day.date);
                }

                if (self.modal.update.dateRange.length > 0) {
                    self.modal.update.dateRange = self.modal.update.dateRange.sort(function(a, b) {
                        return new Date(a) - new Date(b);
                    });
                }
            }
        },
        openModal(id) {
            self.modal.id = id;
            self.modal.range = {
                start: '',
                end: ''
            };
            if (id == 'update') {
                self.modal.size = '';
                self.modal.update = {
                    status: true,
                    statusChange: false,
                    priceChange: false,
                    timeChange: false,
                    price: 0,
                    currency: 'try',
                    dateAttr: {
                        min: false,
                        max: false
                    },
                    dateRange: [],
                    rangeTime: {
                        start: moment(1000, 'HHmm').toDate(),
                        end: moment(1800, 'HHmm').toDate()
                    },
                    time: {
                        start: 1000,
                        end: 1800
                    }
                };

                let first = _.first(self.availability);
                let last = _.last(self.availability);
                self.modal.update.dateAttr = {
                    min: first ? moment(first.data, 'YYYYMMDD').toDate() : false,
                    max: last ? moment(last.data, 'YYYYMMDD').toDate() : false
                };
            } else {
                self.modal.create = {
                    type: _.clone(self.filter.type),
                    price: 0,
                    currency: 'try',
                    range: {
                        start: moment()
                            .clone()
                            .startOf('month')
                            .toDate(),
                        end: moment()
                            .clone()
                            .startOf('month')
                            .add(1, 'month')
                            .subtract(1, 'day')
                            .toDate()
                    },
                    rangeTime: {
                        start: moment(1000, 'HHmm').toDate(),
                        end: moment(1800, 'HHmm').toDate()
                    },
                    date: {
                        start: moment()
                            .clone()
                            .startOf('month')
                            .format('YYYYMMDD'),
                        end: moment()
                            .clone()
                            .startOf('month')
                            .add(1, 'month')
                            .subtract(1, 'day')
                            .format('YYYYMMDD')
                    },
                    time: {
                        start: 1000,
                        end: 1800
                    },
                    min: 30
                };
            }

            self.modal.status = true;
        },
        setDates() {
            _.set(self.filter, 'start', moment(self.range.start).format('YYYYMMDD'));
            _.set(self.filter, 'end', moment(self.range.end).format('YYYYMMDD'));

            self.tableLoading = true;
            self.$store
                .dispatch(AVAILABILITY_REQUEST, self.filter)
                .then(resp => {
                    self.tableLoading = false;
                })
                .catch(err => {
                    self.tableLoading = false;
                    handleError(self, err);
                });
        },
        setModalDates() {
            _.set(self.modal.create.date, 'start', parseInt(moment(self.modal.create.range.start).format('YYYYMMDD')));
            _.set(self.modal.create.date, 'end', parseInt(moment(self.modal.create.range.end).format('YYYYMMDD')));
        },
        setModalTime(id) {
            _.set(self.modal[id].time, 'start', parseInt(moment(self.modal[id].rangeTime.start).format('HHmm')));
            _.set(self.modal[id].time, 'end', parseInt(moment(self.modal[id].rangeTime.end).format('HHmm')));
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(AVAILABILITY_REQUEST, self.filter)
                .then(resp => {
                    self.loading = false;
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        },
        onSubmitCreate() {
            self.modal.loading = true;
            let data = _.cloneDeep(self.modal.create);
            data['uuid'] = _.clone(self.filter.doctor);
            data['price'] = parseFloat(data['price']);

            self.$store
                .dispatch(AVAILABILITY_ADD, data)
                .then(resp => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        async onSubmitUpdate() {
            self.modal.loading = true;
            let modal = _.cloneDeep(self.modal.update);
            let data = { uuid: _.clone(self.filter.doctor), type: _.clone(self.filter.type) };

            data['dateList'] = await Promise.all(
                _.map(modal.dateRange, item => {
                    return parseInt(moment(item).format('YYYYMMDD'));
                })
            );

            if (modal.statusChange) {
                data['operation'] = modal.status ? 'active' : 'passive';
            }

            if (modal.priceChange) {
                data['price'] = modal.price;
                data['currency'] = modal.currency;
            }

            if (modal.timeChange) {
                data['timeList'] = {
                    start: parseInt(moment(modal.rangeTime.start).format('HHmm')),
                    end: parseInt(moment(modal.rangeTime.end).format('HHmm'))
                };
            }

            self.$store
                .dispatch(AVAILABILITY_UPDATE, data)
                .then(resp => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        }
    },
    created() {
        self = this;
        self.loading = true;

        self.$store
            .dispatch(DOCTOR_REQUEST, { pagination: { limit: 10000 }, filter: self.filter, fields: '-_id title uuid name email phone' })
            .then(resp => {
                if (self.doctors.length > 0) {
                    self.filter.doctor = self.doctors[0].uuid;
                    self.getData();
                } else {
                    self.loading = false;
                }
            })
            .catch(err => {
                self.loading = false;
                handleError(self, err);
            });
    }
};
</script>
